import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ScrollToContext } from '@zauberware/react-scroll-to'

import {
  Layout,
  Hero,
  SEO,
  StorySlider,
  Contact,
  Competences,
  Features,
  About,
  Footer,
} from '../components'

import {
  Section,
  Container,
} from '../styled'

import {
  Flex,
} from '@magicsoup.io/stock'

class IndexPage extends Component {
  render() {
    const { addScrollRef } = this.context
    return (
      <Layout>
        <SEO title="Home" keywords={[`transportation`, `g+g`, `gg-trans`]} />
        <Container>
          <Section pt={[6]} pb={[3, 4, 4]}>
            <Hero />
          </Section>
    
          <Section py={[4,4,6]} ref={node=> addScrollRef('competences', node)}>
            <Flex justifyContent='center'>
              <Competences />
            </Flex>
          </Section>
    
          <Section py={[4,4,6]} ref={node=> addScrollRef('services', node)}>
            <Features />
          </Section>
    
          <Section pt={[4,4,6]} ref={node=> addScrollRef('about', node)}
            bg='primary'
            pb='0'
            pt='0'
          >
            <About />
          </Section>
    
          <Section py={[4,4,6]} ref={node=> addScrollRef('story', node)}>
            <Container>
              <StorySlider />
            </Container>
          </Section>
    
          <Section py={[4,4,6]} ref={node=> addScrollRef('contact', node)}>
            <Contact />
          </Section>
        </Container>
        {/* <Footer /> */}
      </Layout>
    )
  }
}

export default IndexPage

IndexPage.contextType = ScrollToContext
